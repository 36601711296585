<template>
  <div>
    <div v-if="evaluationScore && institution" class="container-note mt-1">
      <template v-if="!evaluator_view">
        <strong class="mr-1 text-grade">Nota Obtenida:</strong>
      </template>
      <template v-else>
        <strong class="mr-1 text-grade">
          Nota
          {{
            evaluationScore.final_grade != null
              ? "Obtenida"
              : evaluationScore.manual_grade != null
              ? "Asignada"
              : evaluationScore.automated_grade != null
              ? "Automática"
              : "Obtenida"
          }}:
        </strong>
      </template>
      <div class="evaluation-note">
        <strong>
          <span v-if="!evaluator_view || !closedEvaluationScore">
            {{
              evaluationScore.final_grade != null
                ? evaluationScore.final_grade
                : evaluationScore.manual_grade != null
                ? evaluationScore.manual_grade
                : evaluationScore.automated_grade != null
                ? evaluationScore.automated_grade
                : institution.min_grade | Round
            }}
          </span>
          <span class="d-flex" v-else>
            <template v-if="evaluatee_evaluation.final_grade != null">
              <b-form-input
                size="sm"
                step="0.01"
                :max="institution.max_grade"
                :min="institution.min_grade"
                type="number"
                class="m-0 p-0 w-100 text-center"
                :state="ValidScore"
                v-model="evaluatee_evaluation.final_grade"
                @update="patchEvaluateeScore"
              ></b-form-input>
            </template>
            <template v-else-if="evaluatee_evaluation.manual_grade != null">
              <b-form-input
                size="sm"
                step="0.01"
                :max="institution.max_grade"
                :min="institution.min_grade"
                type="number"
                class="m-0 p-0 w-100 text-center"
                :state="ValidScore"
                v-model="evaluatee_evaluation.manual_grade"
                @update="patchEvaluateeScore"
              ></b-form-input>
            </template>
            <template v-else>
              <b-form-input
                size="sm"
                step="0.01"
                :max="institution.max_grade"
                :min="institution.min_grade"
                type="number"
                class="m-0 p-0 w-100 text-center"
                :state="ValidScore"
                :value="evaluatee_evaluation.automated_grade"
                @update="patchEvaluateeScore"
              ></b-form-input>
            </template>
            <b-icon
              v-if="
                evaluationScore &&
                (evaluationScore.final_grade != null ||
                  evaluationScore.manual_grade != null)
              "
              class="ml-1 style-icon-btn"
              v-b-tooltip.v-secondary.noninteractive.hover="
                'Utilizar nota automática'
              "
              @click="patchAutomatedGrade()"
              icon="arrow-repeat"
            ></b-icon>
          </span>
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { toast } from "@/utils/utils";

export default {
  name: "ViewEvaluationGrade",
  props: {
    user_id: {
      type: Number,
      required: false,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      evaluatee_evaluation: {
        final_grade: null,
        manual_grade: null,
        automated_grade: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      user: "getUser",
      evaluations: names.NEW_EVALUATIONS,
      sections: names.SECTIONS,
      evaluatee_evaluations: names.EVALUATEE_EVALUATIONS,
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    evaluationScore() {
      return this.evaluatee_evaluations.find(
        (x) => x.evaluation == this.evaluation_id && x.evaluatee == this.user_id
      );
    },
    ValidScore() {
      const evaluation_score = this.evaluatee_evaluation;
      if (!this.institution) return null;
      let score = !evaluation_score
        ? this.institution.min_grade
        : evaluation_score.final_grade != null
        ? parseFloat(evaluation_score.final_grade)
        : evaluation_score.manual_grade != null
        ? parseFloat(evaluation_score.manual_grade)
        : evaluation_score.automated_grade != null
        ? parseFloat(evaluation_score.automated_grade)
        : this.institution.min_grade;
      if (
        score < this.institution.min_grade ||
        score > this.institution.max_grade
      )
        return false;
      else if (
        score >= this.institution.min_grade &&
        score <= this.institution.max_grade
      )
        return true;
      return null;
    },
    closedEvaluationScore() {
      if (this.evaluator_view && this.user) {
        if (
          this.section &&
          this.section.is_active &&
          (this.section.professors.includes(this.user.id) ||
            this.user.is_superuser ||
            this.user.groups.includes(1))
        )
          return true;
        else return false;
      } else return false;
    },
  },
  methods: {
    patchEvaluateeScore(score) {
      if (this.timeout) clearTimeout(this.timeout);
      if (
        parseFloat(score) >= this.institution.min_grade &&
        parseFloat(score) <= this.institution.max_grade &&
        this.closedEvaluationScore
      ) {
        this.timeout = setTimeout(() => {
          if (this.evaluationScore.id == null) {
            this.createEvaluateeEvaluation(score);
          } else {
            this.patchEvaluateeEvaluation(score);
          }
        }, 1500);
      }
    },
    createEvaluateeEvaluation(score) {
      this.$store
        .dispatch(names.POST_EVALUATEE_EVALUATION, {
          manual_grade: score,
          evaluatee: this.user_id,
          evaluation: this.evaluation_id,
          attachments: [],
        })
        .then((response) => {
          this.evaluatee_evaluation = response;
          toast("Nota actualizada.");
        });
    },
    patchEvaluateeEvaluation(score) {
      let payload = null;
      if (this.evaluationScore.final_grade != null) {
        payload = {
          evaluatee_evaluation_id: this.evaluationScore.id,
          item: {
            final_grade: score,
          },
        };
      } else {
        payload = {
          evaluatee_evaluation_id: this.evaluationScore.id,
          item: {
            manual_grade: score,
          },
        };
      }
      if (payload != null)
        this.$store
          .dispatch(names.PATCH_EVALUATEE_EVALUATION, payload)
          .then((response) => {
            this.evaluatee_evaluation = response;
            toast("Nota actualizada.");
          });
    },
    patchAutomatedGrade() {
      if (this.closedEvaluationScore) {
        if (this.evaluationScore == null) {
          this.$store.dispatch(names.POST_EVALUATEE_EVALUATION, {
            manual_grade: null,
            evaluatee: this.user_id,
            evaluation: this.evaluation_id,
            attachments: [],
          });
        } else {
          const payload = {
            evaluatee_evaluation_id: this.evaluationScore.id,
            item: {
              manual_grade: null,
              final_grade_grade: null,
            },
          };
          this.$store.dispatch(names.PATCH_EVALUATEE_EVALUATION, payload);
        }
      }
    },
  },
  watch: {
    evaluationScore(value) {
      if (value != null) this.evaluatee_evaluation = value;
    },
  },
  mounted() {
    if (this.evaluationScore != null)
      this.evaluatee_evaluation = this.evaluationScore;
  },
};
</script>

<style scoped>
.container-note {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec88;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5px;
  max-width: 350px;
}
.evaluation-note {
  display: block;
  width: fit-content;
  padding: 8px;
  font-size: 20pt;
  border-radius: 5px;
  border: 2px solid var(--kl-menu-color) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  align-items: center;
}
.text-grade {
  font-size: 13pt;
}
.style-icon-btn {
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  cursor: pointer;
}
</style>